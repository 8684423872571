// extracted by mini-css-extract-plugin
export var lbContainerOuter = "q_qx";
export var lbContainerInner = "q_qy";
export var movingForwards = "q_qz";
export var movingForwardsOther = "q_qB";
export var movingBackwards = "q_qC";
export var movingBackwardsOther = "q_qD";
export var lbImage = "q_qF";
export var lbOtherImage = "q_qG";
export var prevButton = "q_qH";
export var nextButton = "q_qJ";
export var closing = "q_qK";
export var appear = "q_qL";