// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "n_p6 d_bz";
export var storyRowWrapper = "n_hv d_hv d_bG";
export var storyLeftWrapper = "n_p7 d_bw d_bL";
export var storyWrapperFull = "n_p8 d_cz";
export var storyWrapperFullLeft = "n_nQ d_cz d_bw d_bL d_bz";
export var contentWrapper = "n_mC d_hw";
export var storyLeftWrapperCenter = "n_p9 d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "n_qb d_hC";
export var storyHeader = "n_qc d_hB d_s d_cp";
export var storyHeaderCenter = "n_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "n_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "n_qd d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "n_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "n_qf d_fc d_W";
export var imageWrapperFull = "n_qg d_s d_D d_bb d_W";